import markerIcon from '../assets/images/map_marker.png';
import maplibregl from 'maplibre-gl';
import globals from '@js/globals.js';

function createGeoJson(data) {
    let rtn = {
        type: "FeatureCollection",
        features: []
    }
    data.forEach(el => {
        let feature = {
            type: "Feature",
            properties: {
                id: ""
            },
            geometry: {
                type: "Point",
                coordinates: []
            }
        }
        feature.properties.id = el.id;
        feature.geometry.coordinates.push(el.geodata_longitude)
        feature.geometry.coordinates.push(el.geodata_latitude)
        rtn.features.push(feature);
    })
    return rtn;
}

function createSource(map, sourceName, geoJsonData, interactive=true) {
    map.addSource(sourceName, {
            type: 'geojson',
            data: geoJsonData,
            cluster: true,
            clusterMaxZoom: 24, // Max zoom to cluster points on
            clusterRadius: 100 // Radius of each cluster when clustering points (defaults to 50)
        });

        map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: sourceName,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': '#FFFFFF',
                'circle-radius': 24,
                'circle-stroke-color': '#C5BCB7',
                'circle-stroke-width': 2
            }
        });
             
        map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: sourceName,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': '{point_count_abbreviated}',
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 20
            }
        });
        
        map.loadImage(markerIcon, (err, image) => {
            map.addImage('source-marker', image);
            map.addLayer({
                id: 'unclustered-point',
                type: 'symbol',
                source: sourceName,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': 'source-marker',
                    'icon-offset': [0, -15]
                }
            });
        })        

        if (interactive) {
            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', 'clusters', function () {
                map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseenter', 'unclustered-point', function () {
                map.getCanvas().style.cursor = 'pointer';
            }); 
            // Change it back to a pointer when it leaves.
            map.on('mouseleave', 'clusters', function () {
                map.getCanvas().style.cursor = '';
            });
            map.on('mouseleave', 'unclustered-point', function () {
                map.getCanvas().style.cursor = '';
            });         
        }
}

export default {
    updateSource: (map, sourceName, sourceData, interactive=true) => {
        let geoJsonData = createGeoJson(sourceData);

        let source = map.getSource(sourceName);
        
        if (!source) {
            createSource(map, sourceName, geoJsonData, interactive)
        } else {
            source.setData(geoJsonData);
        }
    }
}