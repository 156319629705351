<template>
  <div class="card-text-cont" :style="`--aspect-ratio: ${ratio}`" @click="$emit('clicked')">
    <div class="flow">
      <p class="text--body2 line-clamp" :class="{'text-placeholder': !loaded}">{{subtitleTop}}</p>
      <p v-if="titleSize == 'body2'" class="text--body2-medium line-clamp" :class="{'text-placeholder': !loaded}" :style="`--line-clamp-num: ${titleRowsLimit};`">{{title}}</p>
      <h4 v-else-if="titleSize == 'h4'" class="line-clamp" :class="{'text-placeholder': !loaded}" :style="`--line-clamp-num: ${titleRowsLimit};`">{{title}}</h4>
      <p class="text--body2 line-clamp" :class="{'text-placeholder': !loaded}">{{subtitleBottom}}</p>
    </div>
    <div >
      <div v-if="like" class="icon-container cover">
        <base-icon name="favorite"/>        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InterfaceCardSingleText',
  props: {
    title: String,
    subtitleTop: String,
    subtitleBottom: String,
    like: {
      type: Boolean,
      required: false,
      default: false
    },
    titleSize: {
      type: String,
      required: false,
      default: "h4",
      validator: (val) => ["h4", "body2"].indexOf(val) >= 0
    },
    titleRowsLimit: {
      type: Number,
      required: false,
      default: 4,
      validator: (val) => val > 0
    },
    loaded: {
      type: Boolean,
      required: false,
      default: true
    },
    ratio: {
      type: String,
      required: false,
      default: '2.5/1',
      validator: (val) => {
        let numbers = val.split('/');
        return numbers.every(el => !isNaN(el));
      }
    }
  },
}
</script>

<style lang="scss">
.card-text-cont {
  --aspect-ratio: 2.5/1;
  display: flex;
  --flow-space: var(--s0);
  background-color: var(--color-white);
  aspect-ratio: var(--aspect-ratio);
  overflow: hidden;
  cursor: pointer;

  p, h4 {
    --line-clamp-num: 1;    
  }


  > div:first-child {
    flex: 1;
    padding-top: calc(var(--s0)/2);
  }

  .icon-container {
    width: var(--s3);
  }
}
</style>
