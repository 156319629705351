<template>
  <div class="map-container">
    <interface-map-popup 
      v-if="!mobile && mapLoaded"
      :showed="!!popup.id"
      :id="popup.id"
      :coordinates="popup.coordinates"
      @close="closePopup">

      <div class="map-popup">
        <a :href="`sheet/${sheetSelected.slug}`" @click.prevent>
          <base-card-single-sheet 
            :sheet="sheetSelected"
            is-popup
            @clicked="$emit('popup-clicked', {id: sheetSelected.id, slug: sheetSelected.slug})"
          />
        </a>
      </div>


    </interface-map-popup>

    <interface-map-popup 
      v-if="!mobile && mapLoaded"
      :showed="!!popupCluster.id"
      :id="popupCluster.id"
      :coordinates="popupCluster.coordinates"
      @close="closePopupCluster">

      <!-- <div style="width: 100px; height: 100px;background-color: red;">CLUSTER</div> -->
      <div class="map-popup-cluster">
        <div class="map-popup-cluster-container | custom-scrollbar">
          <div class="map-popup-cluster-item | bordered-bottom" v-for="s in sheetsSelected" :key="s.id" data-border-color="ultralight">
            <a :href="`sheet/${s.slug}`" @click.prevent>
              <interface-card-single-text 
                :title="s.title"
                :subtitle-top="s.category"
                :subtitle-bottom="s.institution"
                is-popup
                title-size="body2"
                :title-rows-limit="2"
                @clicked="$emit('popup-clicked', {id: s.id, slug: s.slug})"
              />              
            </a>
          </div>          
        </div>
      </div>

    </interface-map-popup>

  </div>
</template>

<script>
import maplibregl from 'maplibre-gl';
import globals from '@js/globals.js';
import utils from '@js/utils.js';
import maplib from '@js/maplib.js';
import InterfaceMapPopup from '@components/InterfaceMapPopup.vue';
import InterfaceCardSingleText from '@components/InterfaceCardSingleText.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'InterfaceMap',
  components: { InterfaceMapPopup, InterfaceCardSingleText },
  props: {
    markers: {
      type: Array,
      required: false,
      default: () => []
    },
    interactive: {
      type: Boolean,
      required: false,
      default: true
    },
    center: { 
      type: Array, // [lng, lat]
      required: false,
      default: () => []
    },
    zoom: {
      type: Number,
      required: false,
      default: -1
    },
    bbox: {
      type: Array, // [sw, ne] -> [[lng, lat], [lng, lat]]
      required: false,
      default: () => []
    },
    controls: {
      type: Boolean,
      required: false,
      default: false
    },
    mobile: {
      type: Boolean,
      required: false,
      default: false
    },
    mapPadding: {
      type: Object,
      required: false,
      default: () => {
        return {
          top: 10,
          bottom: 10,
          left: 10,
          right: 10
        }
      }
    },
    interactiveMarkers: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      map: null,
      mapEl: null,
      styleUrl: `${globals.MAPTILER_STYLE_URL}?key=${globals.MAPTILER_TOKEN}`,
      mapLoaded: false,
      popup: {
        id: null,
        coordinates: []
      },
      popupCluster: {
        id: null,
        coordinates: []
      },
      voices: [ //TEMP
        {
          id: 1,
          type: "voice", 
          title: "titolo scheda", 
          subtitleTop: "sottotitolo 1", 
          subtitleBottom: "sottotitolo 2", 
          image: "https://culturalimentare.beniculturali.it/sources/15852/media/"
        }
      ]
    }
  },
  provide() {
    const self = this;
    return {
      get map() {
        return self.map;
      }
    }
  },
  computed: {
    ...mapGetters('sheets', ['sheetsByList', 'sheetSelected']),
    sheetsSelected() {
      return this.sheetsByList('selected');
    },
    initCenter() {
      return this.center.length ? this.center : undefined;
    },
    initZoom() {
      return this.zoom >= 0 ? this.zoom : 0;
    },
    initBBox() {
      return (this.zoom >= 0 && this.center.length) ? undefined : this.bbox.length ? this.bbox : globals.MAP_BOUNDING_BOX;
    },
    filteredMarkers() {
      return this.markers.filter(el => el.geodata_latitude && el.geodata_longitude);
    }
  },
  watch: {
    markers(n) {
      this.initMarkers();
    }
  },
  methods: {
    testfunc(arg) {
      // console.log("InterfaceMap test func", arg)
    },
    onMapLoad() {
      this.$emit('loaded');
      this.mapLoaded = true;
      if (this.controls) this.initControls();
      this.initMarkers();
      this.initInteractions();
      // this.initResizeObserver();
    },
    initMarkers() {
      if (this.mapLoaded) {
        maplib.updateSource(this.map, "ethnographic_sources", this.filteredMarkers, this.interactiveMarkers);
      }
    },
    initInteractions() {
      if (this.interactiveMarkers) {
        // When a click event occurs on a feature in the places layer, open a popup at the
        // location of the feature, with description HTML from its properties.
        this.map.on('click', 'unclustered-point', e => {
          e.preventDefault();

          var coordinates = e.features[0].geometry.coordinates.slice();
          var id = e.features[0].properties.id;
           
          // Ensure that if the map is zoomed out such that multiple
          // copies of the feature are visible, the popup appears
          // over the copy being pointed to.
          while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
          }
          
          this.$set(this.popup, "coordinates", coordinates);
          this.$set(this.popup, "id", id);
          this.closePopupCluster();
          this.$emit("marker-clicked", id);

          this.centerMapOnCoords(coordinates);
        });

        this.map.on('click', 'clusters', (e) => {
            e.preventDefault();
            var features = this.map.queryRenderedFeatures(e.point, {
              layers: ['clusters']
            });

            var clusterId = features[0].properties.cluster_id;
            var pointCount = features[0].properties.point_count;
            var clusterSource = this.map.getSource('ethnographic_sources');
             
            clusterSource.getClusterLeaves(clusterId, pointCount, 0, (error, features) => {
              // console.log('Cluster leaves:', error, features);
              let latitudes = features.map(el => el.geometry.coordinates[1])
              let longitudes = features.map(el => el.geometry.coordinates[0])
              if (utils.allEqual(latitudes) && utils.allEqual(longitudes)) {
                let coordinates = features[0].geometry.coordinates;
                let ids = features.map(el => el.properties.id);
                // open popup of clustered results
                this.$set(this.popupCluster, "coordinates", coordinates);
                this.$set(this.popupCluster, "id", clusterId);
                this.closePopup();
                this.centerMapOnCoords(coordinates);
                this.$emit("cluster-clicked", ids);

              } else {
                this.closePopup();
                this.closePopupCluster();
                this.map.getSource('ethnographic_sources').getClusterExpansionZoom(
                  clusterId,
                  (err, zoom) => {
                    if (err) return;
                    this.map.easeTo({
                      center: features[0].geometry.coordinates,
                      zoom: Math.min(zoom, globals.MAP_CLUSTER_MAX_ZOOMIN),
                      padding: this.mapPadding
                    });
                  }
                );
              }
            })
          });

        this.map.on('click', e => {
          if (e.defaultPrevented === false) {
            this.closePopupHandler();
            this.$emit("map-clicked");
          }
        });          
      }
    },
    fitBounds(bounds) {
      this.map.fitBounds(bounds, {
        padding: this.mapPadding
      });
    },
    closePopupHandler() {
      this.closePopup();
      this.closePopupCluster();
      this.$emit('popups-closed');
    },
    closePopup() {
      this.$set(this.popup, "coordinates", []);
      this.$set(this.popup, "id", null);
    },
    closePopupCluster() {
      this.$set(this.popupCluster, "coordinates", []);
      this.$set(this.popupCluster, "id", null);
    },
    initResizeObserver() {
      new ResizeObserver(()=>{this.map.resize()}).observe(this.$el);
    },
    resize() {
      if (this.map) {
        this.map.resize();
      }
    },
    initControls() {
      var nav = new maplibregl.NavigationControl({
        showCompass: false,
      });
      this.map.addControl(nav, 'top-right');
    },
    centerMapOnCoords(coords) {
      this.map.panTo([coords[0], coords[1]], {
        duration: 250,
        offset: [0,this.mobile ? 0 : 200],
        padding: this.mapPadding
      });
    }
  },
  mounted() {
    this.mapEl = this.$el;
    this.map = new maplibregl.Map({
      container: this.mapEl, // container id
      style: this.styleUrl,
      zoom: this.initZoom,
      center: this.initCenter,
      bounds: this.initBBox, // starting position (overrides zoom and center)
      interactive: this.interactive,
      attributionControl: !this.mobile,
      pitchWithRotate: false, //disable pitch
      dragRotate: false, //disable rotation,
      fitBoundsOptions: {
        padding: this.mapPadding
      }        
    });

    // this.map.setMaxZoom(15);

    this.map.on("load", this.onMapLoad);

    if (this.mobile) this.map.addControl(new maplibregl.AttributionControl({
      compact: true
    }), 'bottom-right');

    // this.map.on("move", () => {
    //   console.log(this.map.getZoom())
    // })
  }
}
</script>

<style lang="scss">
.map-container {
  height: 100%;
  --map-popup-width: 20rem;
  --map-popup-height: 24rem;

  .map-popup-cluster {
    height: var(--map-popup-height);
    
    border-right: solid calc(var(--s0)/2) var(--color-white);

    .map-popup-cluster-container {
      height: 100%;
      width: 100%;
      overflow-y: scroll;
      margin-top: var(--s2);
      padding-left: var(--s2);
      padding-right: var(--s2);
      
      .map-popup-cluster-item {      
        padding-bottom: var(--s2);
      }
      .map-popup-cluster-item:not(:first-child) {
        padding-top: var(--s2);
      }      
    }
  }

  .mapboxgl-ctrl-top-right .mapboxgl-ctrl,
  .maplibregl-ctrl-top-right .maplibregl-ctrl {
    margin: var(--s1) var(--s1) 0 0;
  }

  .mapboxgl-ctrl-group button,
  .maplibregl-ctrl-group button {
    width: var(--map-button-size);
    height: var(--map-button-size);
  }

  .mapboxgl-ctrl-group,
  .maplibregl-ctrl-group {
    border-radius: 0.5rem;
  }

  .mapboxgl-ctrl-group:not(:empty),
  .maplibregl-ctrl-group:not(:empty) {
    box-shadow: var(--map-button-shadow);
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-in .mapboxgl-ctrl-icon,
  .maplibregl-ctrl button.maplibregl-ctrl-zoom-in .maplibregl-ctrl-icon {
    background-image: url('../assets/icons/zoom-in.svg');
  }

  .mapboxgl-ctrl button.mapboxgl-ctrl-zoom-out .mapboxgl-ctrl-icon,
  .maplibregl-ctrl button.maplibregl-ctrl-zoom-out .maplibregl-ctrl-icon {
    background-image: url('../assets/icons/zoom-out.svg');
  }
}
.map-popup,
.map-popup-cluster {
  overflow: hidden;
  border-radius: 1.25rem;
  background-color: var(--color-white);
  box-shadow: var(--shadow-small);
  width: var(--map-popup-width);
}
</style>
