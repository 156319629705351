<script>
import maplibregl from 'maplibre-gl';

export default {
  name: 'InterfaceMapPopup',
  props: {
    id: {
      type: Number
    },
    coordinates: {
      type: Array,
      required: true
    },
    showed: {
      type: Boolean,
      default: false
    },
    // map: {
    //   type: Object,
    //   required: true
    // }
  },
  data() {
    return {
      initial: true,
      popup: undefined
    }
  },
  inject: {
    map: {
      default: null
    }
  },
  computed: {
    open: {
      get() {
        if (this.popup !== undefined) {
          return this.popup.isOpen();
        }
        return false;
      },
      set(value) {
        if (this.map && this.popup) {
          if (!value) {
            this.popup.remove();
            // console.log("popup removed from map")
          } else {
            this.popup.addTo(this.map);
            // console.log("popup added to map")
          }
        }
      }
    }
  },
  mounted() {
    this.addPopup();
    this.initial = false;
  },
  beforeDestroy() {
    if (this.map) {
      this.popup.remove();
    }
  },
  watch: {
    coordinates(next) {
      if (this.initial) return;
      if (next.length) this.popup.setLngLat(next);
    },
    id(next) {
      // if (next) this.popup.setHTML(next);
    },
    showed(next, prev) {
      if (next !== prev) {
        this.open = next;
      }
    }
  },
  methods: {
    addPopup() {
      this.popup = new maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        offset: 30,
        maxWidth: 'none'
      });

      if (this.coordinates.length) {
        this.popup.setLngLat(this.coordinates);
      }

      this.popup.on("open", () => {
        // console.log("popup open handler")
        this.$emit("open");
      })

      this.popup.on("close", () => {
        // console.log("popup close handler")
        this.$emit("close");
      })

      this.popup.setDOMContent(this.$slots.default[0].elm);
    }
  },
  render(h) {
    return h(
      "div",
      [this.$slots.default]
    );
  }
}
</script>

<style lang="scss">
.mapboxgl-popup-content,
.maplibregl-popup-content {
  padding: 0px;
  background-color: transparent;
  box-shadow: unset;
}

.mapboxgl-popup-tip,
.maplibregl-popup-tip {
  display: none;
}
</style>
